export const ReadSectionSortMap = {
  'season-1': 1,
  'season-2': 2,
  'season-3': 3,
  'women-in-christian-history':4,
  blog: 5,
};

export const sortOrderSeason1 = {
  episodes: 1,
  locations: 2,
  movements: 3,
  people: 4,
};

export const sortOrderSeason2 = {
  episodes: 1,
  people: 2,
  'pioneer-stories': 3,
  'historical-milestones': 4,
};

export const sortOrderSeason3 = {
  episodes: 1,
  'the-7-churches': 2,
  empires: 3,
};

export const sortBySeasonMap = {
  'season-1': sortOrderSeason1,
  'season-2': sortOrderSeason2,
  'season-3': sortOrderSeason3,
};
