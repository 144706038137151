const ADVERTS_AND_TRAILERS = ['adverts', 'trailers', '360-tours'];
const SERIES = ['season-1', 'season-2', 'season-3', 'the-7-churches-of-revelation', 'the-great-controversy'];
const WatchSectionSortMap = {
  series: {
    'season-1': 1,
    'season-2': 2,
    'season-3': 3,
    'the-7-churches-of-revelation': 4,
    'the-great-controversy': 5,
    'women-in-christian-history':6
  },
  documentaries: 2,
  'locations-from-the-sky': 3,
  'interviews': 5,  
  peopleAndplaces: 8,
  bts: {
    'season-1': 1,
    'season-2': 2,
    'season-3': 3,
    'the-7-churches-of-revelation': 4,
    'interviews': 5,
  },
  bible: 4,
  '360-tours': 5,
  people: 6,
  places: 7,
  adverts: 8,
  trailers: 9,
};

const season3SubCategories = {
  'creation-to-kings': 'Creation to Kings',
  'kings-to-captivity': 'Kings to Captivity',
  'manger-to-messiah': 'Manger to Messiah',
  'pentecost-to-patmos': 'Pentecost to Patmos',
};

// Return author names associated with episode.
const hasRelatedAuthor = (list) => {
  const peopleList = list.map((elm) => elm?.name?.authorName).filter((e) => e);

  return { hasPeople: !!peopleList.length, authorNames: peopleList };
};

// Return place names associated with episode.
const hasRelatedPlace = (list) => {
  const placesList = list.map((elm) => elm?.name?.placeName).filter((e) => e);

  return { hasPlaces: !!placesList.length, placesName: placesList };
};

/**
 * Normalize flatten watch episodes data based on category.
 * Most watch category have different logic on how they should be grouped.
 * SERIES: If the category is 'season-1', 'season-2', 'season-3', 'churches-7' then group them as series.
 * PEOPLE/PLACES: If the episode data has associated people/place values then group them as people/places.
 * ADVERTS/TRAILERS/360-tours: Episode category is 'adverts', 'trailers', '360-tours'.
 * BIBLE:Bible is episodes that are season-3 and that have sub-periods.
 * TIMEFRAMES: The values that have subPeriods are timeFrames.
 * BTS: If bts field is "YES".
 *
 * Response Data:
 * {
 *   "categoryName": [a,b,c] //if no subcategory
 *   "categoryName2": {subCategory1: [a, b,c ]: subCategory2: [a1,b1,c1]}
 * }
 *
 * @param  {} watchCategories
 * @param  {} shouldAddTimeFrames=false
 * @param  {} shouldAddPeopleAndPlaces=false
 * @param  {} shouldShowBTSInSeasons=false
 */
const normalizeWatchCategories = (
  watchCategories,
  shouldAddTimeFrames = false,
  shouldAddPeopleAndPlaces = false,
  shouldShowBTSInSeasons = false
) => {
  return watchCategories.reduce((acc, value) => {
    // If categoryType is of SERIES then group them as series.
    const filterSeriesCategory = (category, value) => {
      if (SERIES.includes(category) && value?.data?.bts === 'NO') {
        const uid = value?.uid;
        const formattedURL = formateURLLink(uid);
        const URL = `/video_episodes/${formattedURL}`;
        acc = {
          ...acc,
          ['series']: !!acc['series']
            ? {
                ...acc['series'],
                [category]: !!acc['series'][category]
                  ? [...acc['series'][category], { ...value, url: URL }]
                  : [{ ...value, url: URL }],
              }
            : { [category]: [{ ...value, url: URL }] },
        };
      }
    };

    const filterDocumentaries = (category, value) => {
      if (category === 'documentaries') {
        const uid = value?.uid;
        const formattedURL = formateURLLink(uid);
        const url = `/video_episodes/${formattedURL}`;
        acc = {
          ...acc,
          ['documentaries']: !!acc['documentaries']
            ? [...acc['documentaries'], { ...value, url }]
            : [{ ...value, url }],
        };
      }
    };
    //filter Interviews category
    const filterInterviews = (category, value) => {
      if (category === 'interviews') {
        const uid = value?.uid;
        const formattedURL = formateURLLink(uid);
        const url = `/video_episodes/${formattedURL}`;
        acc = {
          ...acc,
          ['interviews']: !!acc['interviews']
            ? [...acc['interviews'], { ...value, url }]
            : [{ ...value, url }],
        };
      }
    };

    const filterLocationsFromTheSky = (category, value) => {
      if (category === 'locations-from-the-sky') {
        const uid = value?.uid;
        const formattedURL = formateURLLink(uid);
        const url = `/video_episodes/${formattedURL}`;
        acc = {
          ...acc,
          ['locations-from-the-sky']: !!acc['locations-from-the-sky']
            ? [...acc['locations-from-the-sky'], { ...value, url }]
            : [{ ...value, url }],
        };
      }
    };

    // Bible is episodes that are season-3 and that have sub-periods.
    const filterBible = (category, value) => {
      if (category === 'season-3') {
        const [subPeriods] = value?.data?.subPeriods;

        if (subPeriods) {
          const subPeriod = subPeriods?.subPeriod?.slug;

          if (subPeriod) {
            // Format ad-1900---2000 to ad-1900-2000.
            const uid = value?.uid;
            const formattedURL = formateURLLink(uid);
            const url = `/video_episodes/${formattedURL}`;
            const formattedSubPeriod = subPeriod.replace('---', '-');
            acc = {
              ...acc,
              ['bible']: !!acc['bible']
                ? {
                    ...acc['bible'],
                    [formattedSubPeriod]: !!acc['bible'][formattedSubPeriod]
                      ? [...acc['bible'][formattedSubPeriod], { ...value, url }]
                      : [{ ...value, url }],
                  }
                : { [formattedSubPeriod]: [{ ...value, url }] },
            };
          }
        }
      }
    };

    const filterAdvertsTrailersAnd360Tours = (category, value) => {
      if (ADVERTS_AND_TRAILERS.includes(category)) {
        const selectedCategory = ADVERTS_AND_TRAILERS.find((elm) => elm === category);
        const uid = value?.uid;
        const formattedURL = formateURLLink(uid);
        const url = `/video_episodes/${formattedURL}`;

        acc = {
          ...acc,
          [selectedCategory]: !!acc[selectedCategory]
            ? [...acc[selectedCategory], { ...value, url }]
            : [{ ...value, url }],
        };
      }

      return;
    };
    //

    // If bts field is "YES" then filter them into BTS section.
    const filterBTS = (value) => {
      const bts = value?.data?.bts;
      const uid = value?.uid;
      const formattedURL = formateURLLink(uid);
      const url = `/video_episodes/${formattedURL}`;

      if (bts === 'YES') {
        if (shouldShowBTSInSeasons) {
          const categories = value?.data?.categories.map((elem) => elem?.episodeCategory?.categoryType);
          const categoryType = !!categories.length && categories[0];
          acc = {
            ...acc,
            ['bts']: !!acc['bts']
              ? {
                  ...acc['bts'],
                  [categoryType]: !!acc['bts'][categoryType]
                    ? [...acc['bts'][categoryType], { ...value, url }]
                    : [{ ...value, url }],
                }
              : { [categoryType]: [{ ...value, url }] },
          };
        } else {
          acc = {
            ...acc,
            ['bts']: !!acc['bts'] ? [...acc['bts'], { ...value, url }] : [{ ...value, url }],
          };
        }
      }

      return;
    };

    const filterPeopleAndPlaces = (value) => {
      const peopleList = value?.data?.people;
      const placesList = value?.data?.places;
      const uid = value?.uid;
      const formattedURL = formateURLLink(uid);
      const url = `/video_episodes/${formattedURL}`;

      const { hasPeople, authorNames } = hasRelatedAuthor(peopleList);
      const { hasPlaces, placesName } = hasRelatedPlace(placesList);

      if (hasPeople) {
        authorNames.map((authorName) => {
          acc = {
            ...acc,
            ['people']: !!acc['people']
              ? {
                  ...acc['people'],
                  [authorName]: !!acc['people'][authorName]
                    ? [...acc['people'][authorName], { ...value, url }]
                    : [{ ...value, url }],
                }
              : { [authorName]: [{ ...value, url }] },
          };
        });
      }

      if (hasPlaces) {
        placesName.map((placeName) => {
          acc = {
            ...acc,
            ['places']: !!acc['places']
              ? {
                  ...acc['places'],
                  [placeName]: !!acc['places'][placeName]
                    ? [...acc['places'][placeName], { ...value, url }]
                    : [{ ...value, url }],
                }
              : { [placeName]: [{ ...value, url }] },
          };
        });
      }
    };

    filterBTS(value);
    shouldAddPeopleAndPlaces && filterPeopleAndPlaces(value);

    const categories = value?.data?.categories.map((elem) => elem?.episodeCategory?.categoryType);

    categories.map((category) => {
      filterSeriesCategory(category, value);
      filterBible(category, value);
      filterDocumentaries(category, value);
      filterLocationsFromTheSky(category, value);
      filterInterviews(category, value);
      filterAdvertsTrailersAnd360Tours(category, value);
    });
    return acc;
  }, {});
};

/**
 * Format URL to remove dot or multiple consecutive hyphen.
 * Sample unformatted URL are
 * 1. joshua-caleb-the-12-spies--40-years-in-the-wilderness
 * 2. st.-andrews-behind-the-scenes
 * 3. ohio-pennsylvania---behind-the-scenes
 */
const formateURLLink = (url) => {
  let formattedURL = '';
  formattedURL = url.replace('.', '');

  // Regex used with select more than one hyphen.
  formattedURL = formattedURL.replace(/-{2,}/g, '-');

  return formattedURL;
};

const normalizePeople = (people) => {
  return people.reduce((acc, value) => {
    const data = value?.node?.data;
    const uid = value?.node?.uid;
    const authorName = data?.title?.text;

    const details = { data, uid };
    acc = {
      ...acc,
      ['people']: !!acc['people']
        ? {
            ...acc['people'],
            [authorName]: !!acc['people'][authorName] ? details : details,
          }
        : { [authorName]: details },
    };

    return acc;
  }, {});
};

const normalizePlaces = (places) => {
  return places.reduce((acc, value) => {
    const data = value?.node?.data;
    const uid = value?.node?.uid;
    const placeName = data?.placeName[0]?.text;

    const details = { data, uid };

    acc = {
      ...acc,
      ['places']: !!acc['places']
        ? {
            ...acc['places'],
            [placeName]: !!acc['places'][placeName] ? details : details,
          }
        : { [placeName]: details },
    };

    return acc;
  }, {});
};

/**
 * GET ThumbNail Link of Episodes with youtubeId and quality
 *
 * @param  {} episode
 * @param  {} quality="mqdefault"
 */
const getEpisodeThumbnail = (episode, quality = 'mqdefault') => {
  let thumbnailUrl = '';
  const youtubeIds = episode?.youtubeId;

  if (youtubeIds.length) {
    thumbnailUrl = youtubeIds[0].text;

    if (thumbnailUrl.includes('youtube.com')) {
      thumbnailUrl = thumbnailUrl.split('v=')[1];
      const ampersandPosition = thumbnailUrl.indexOf('&');
      if (ampersandPosition !== -1) {
        thumbnailUrl = thumbnailUrl.substring(0, ampersandPosition);
      }
    }
    thumbnailUrl = !!episode?.thumbnailImage?.url
      ? episode?.thumbnailImage.url
      : `https://img.youtube.com/vi/${thumbnailUrl}/${quality}.jpg`;
  }
  return thumbnailUrl;
};

/**
 *
 * Removes the hypen from string
 * In case of peopleAndplaces, bts and 360-tours return values are People and Places,
 * Behind the scenes and 360 respectively
 *
 *
 * @param  {} title
 */
const getFormatedEpisodesCategoryTitle = (title = '') => {
  return title === 'peopleAndplaces'
    ? 'People and Places'
    : title === 'bts'
    ? 'Behind the scenes'
    : title === '360-tours'
    ? '360'
    : title.includes('ad')
    ? title.replace('-', ' ')
    : title.replace(/-/g, ' ');
};

/**
 * isForNav is passed because in Nav Bar there is category  peopleAndplaces as single category and in index page there is no time
 * frame category and  people and places as different section category
 * @param  {} normalizedWatchData
 * @param  {} isForNav
 */
const sortNormalizedWatchData = (normalizedWatchData, isForNav) => {
  const sortedWatchCategory = { ...WatchSectionSortMap };
  if (isForNav) {
    delete sortedWatchCategory.people;
    delete sortedWatchCategory.places;
  } else {
    delete sortedWatchCategory.peopleAndplaces;
    delete sortedWatchCategory.timeFrame;
  }

  Object.keys(sortedWatchCategory).forEach((item, index) => {
    if (index === 0) {
      Object.keys(sortedWatchCategory[item]).map((seriesItem) => {
        sortedWatchCategory[item][seriesItem] = normalizedWatchData[item][seriesItem];
      });
    } else {
      sortedWatchCategory[item] = normalizedWatchData[item];
    }
  });
  return sortedWatchCategory;
};

/**
 * @param  {} episodeDetails
 * @param  {} languageList
 */
const getLanguageCode = (episodeDetails, languageList) => {
  if (episodeDetails?.data?.language === 'Non English Subtitles') {
    const languageCode = languageList?.find((item) => {
      const languageTitle = episodeDetails?.data?.categories?.find(
        (category) => category?.episodeCategory?.uid === item?.uid
      )?.episodeCategory?.uid;
      return item?.uid === languageTitle;
    })?.data?.languageCode[0]?.text;
    return languageCode;
  }
  return 'en';
};

/**
 * @param  {} firstContent
 * @param  {} secondContent
 *
 *returns sliceNumber according to content
 */
const getSliceNumber = (firstContent, secondContent, currentLists, maxValue = 4) => {
  if ((firstContent === 1 && secondContent > 1) || (secondContent === 1 && firstContent > 1)) {
    return maxValue - 2;
  }
  if ((firstContent === 0 && secondContent > 0) || (secondContent === 0 && firstContent > 0)) {
    if (currentLists?.length < maxValue - 1) {
      return maxValue - 2;
    }
    return maxValue - 1;
  }
  if (firstContent > 1 && secondContent > 1) {
    if (currentLists?.length < 1) {
      return maxValue - 2;
    }
    return 1;
  }
  return maxValue;
};

module.exports = {
  normalizeWatchCategories,
  normalizePeople,
  normalizePlaces,
  formateURLLink,
  getEpisodeThumbnail,
  getFormatedEpisodesCategoryTitle,
  sortNormalizedWatchData,
  getLanguageCode,
  getSliceNumber,
  season3SubCategories,
  WatchSectionSortMap,
};
