const ReadSectionEnum = {
  SEASON_1: 'season-1',
  SEASON_2: 'season-2',
  SEASON_3: 'season-3',
  WOMEN_IN_CHRISTIAN_HISTROY : 'women-in-christian-history',
  BLOG: 'blog',
};

const FEATURED_SECTION = 'featured';

module.exports = {
  ReadSectionEnum,
  FEATURED_SECTION,
};
